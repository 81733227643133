import { FC } from "react";
import photoDT from "../assets/images/jamesfrysinger-picture-dt-3.jpg";
import photoMO from "../assets/images/jamesfrysinger-picture-mo-3.jpg";
import Heading from "./common/Heading";
import Paragraph from "./common/Paragraph";
import Section from "./common/Section";

const AboutMe: FC = () => {
  return (
    <Section>
      <Heading heading="About Me" type="h2" />
      <Paragraph>
        <picture>
          <source media="(max-width: 639px)" srcSet={photoMO} />
          <source media="(min-width: 640px)" srcSet={photoDT} />
          <img
            src={photoDT}
            alt="Photo of James Frysinger Software and E-Commerce Engineer"
            className="float-right w-full sm:w-1/2 min-w-[350px] sm:pl-10 pt-2 pb-4 sm:pb-2 opacity-95"
          />
        </picture>
        I'm James Frysinger, a highly skilled front-end engineer for e-commerce,
        web and software with 15+ years of experience developing, implementing,
        and maintaining high-performing, functional and visually appealing user
        interfaces. Proven ability to build software and websites using
        JavaScript, React/Typescript, HTML and CSS. Expertise in responsive
        development, SEO, accessibility and performance. I have worked with
        brands such as Nike, Calvin Klein, Tommy Hilfiger, Speedo, Izod, Van
        Heusen, Wolters Kluwer and more.
      </Paragraph>
    </Section>
  );
};

export default AboutMe;
