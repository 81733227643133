import { FC } from "react";
import Heading from "./common/Heading";
import ListStyle from "./common/ListStyle";
import Paragraph from "./common/Paragraph";
import Section from "./common/Section";

const SkillsExperience: FC = () => {
  return (
    <Section>
      <Heading heading="Expertise" type="h2" />
      <Paragraph>
        A multifaceted skill set that covers front-end development and design,
        with a blend of technical expertise, leadership qualities, and
        entrepreneurial spirit.
      </Paragraph>
      <div className="flex flex-wrap pt-3">
        <ul className="w-1/2 sm:w-1/4 sm:pr-6">
          <ListStyle>JavaScript</ListStyle>
          <ListStyle>React</ListStyle>
          <ListStyle>TypeScript</ListStyle>
          <ListStyle>jQuery</ListStyle>
          <ListStyle>HTML</ListStyle>
        </ul>
        <ul className="w-1/2 sm:w-1/4 sm:pl-6">
          <ListStyle>CSS</ListStyle>
          <ListStyle>Jest</ListStyle>
          <ListStyle>Unit Testing</ListStyle>
          <ListStyle>API Integration</ListStyle>
          <ListStyle>A/B Testing</ListStyle>
        </ul>
        <ul className="w-1/2 sm:w-1/4 sm:pl-6">
          <ListStyle>Pixel Integration</ListStyle>
          <ListStyle>Figma</ListStyle>
          <ListStyle>UI/UX Design</ListStyle>
          <ListStyle>GitHub</ListStyle>
          <ListStyle>CI/CD</ListStyle>
        </ul>
        <ul className="w-1/2 sm:w-1/4 sm:pl-6">
          <ListStyle>Docker</ListStyle>
          <ListStyle>Jenkins</ListStyle>
          <ListStyle>Agile Development</ListStyle>
          <ListStyle>JIRA</ListStyle>
        </ul>
      </div>
    </Section>
  );
};

export default SkillsExperience;
