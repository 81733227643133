import { FC, ReactNode } from "react";

interface IParagraph {
  children: ReactNode;
}
const Paragraph: FC<IParagraph> = ({ children }) => {
  return <p className="leading-7 sm:leading-8 text-lg">{children}</p>;
};

export default Paragraph;
