import { FC } from "react";

const Footer: FC = () => {
  return (
    <div className="text-sm text-neutral-400 leading-6 mb-10">
      *This website was designed using Figma and developed with React/TypeScript
      and TailwindCSS.
    </div>
  );
};

export default Footer;
